export interface ITranslationKeys {
    cancel: any;
    invalidTime: any;
    save: any;
    showOnMap: any;
    download: any;
    downloadInfo: any;
    welcomeHi: any;
    welcomeInfo: any;
    acceptPayments: any;
    forum: {
        create: any;
        hidden: any;
        delete: any;
        createPage: any;
        createTheme: any;
        sendMessage: any;
        created: any;
        updated: any;
        startedBy: any;
        comment_base: {
            comment: any;
            comment_0: any;
            comment_1: any;
            comment_2: any;
            comment_3: any;
            comment_4: any;
            comment_5: any;
            comment_plural: any;
        }
        vehicle: {
            vehicle_0: any;
            vehicle_1: any;
            vehicle_2: any;
            vehicle: any;
            vehicle_plural: any;
        };
    }
    account: {
        newEmail: any;
        changeEmail: string;
        langSelector: string;
        activateToken: string;
        acceptToken: string;
        resetHwid: string;
        changePassword: string;
        email: string;
        confirmEmail: string;
        confirmedEmail: string;
        hwid: string;
    }

    lefPanel: {
        dashboard: {
            title: string,
            main: string,
            forum: string,
            buy: string,
            plugins: string,
            addPlugin: string,
            localPlugin: string,
            cloudConfig: string,
            account: string,
        },
        admin: {
            title: string,
            users: string,
            logs: string,
            statistics: string,
            loadLibrary: string,
            pluginManager: string,
            oldMenu: string
        }
        hide: string;
    },
    mainPage: {
        startDota: string;
        downloadingResources: string;
        downloadClient: string;
        downloadingClient: string;
        startingClient: string;
        startDivineClient: string;
        systemStatus: string;
        working: string;
        paymentBlock: {
            funPay: {
                btn: string,
                tooltip: string
            },
            freeKassa: {
                btn: string,
                tooltip: string
            },
            sellix: {
                btn: string,
                tooltip: string
            },
            paymaster: {
                btn: string,
                tooltip: string
            },
            payDigi: {
                btn: string,
                tooltip: string
            },
            primePayments: {
                btn: string,
                tooltip: string
            },
            paymax: {
                btn: string,
                tooltip: string
            },
            paypal: {
                btn: string,
                tooltip: string
            },
            reseller: {
                btn: string,
                tooltip: string
            },
            chineseReseller: {
                btn: string,
                tooltip: string
            },
            amount: string,
            title: string,
            discount: string,
            tooltip: {
                title: string,
                row1: string,
                row2: string,
                row3: string,
                extra: string,
            }
        };
        transactionBlock: {
            title: string,
        };
        subLifeTimeBlock: {
            title: string,
        };
        uptime: string;
        protection: string;
        lastPluginsUpdate: string;
        showAll: string;
        othersBlock: {
            activeToken: {
                title: string;
                description: string;
                btnText: string;
            },
            hwid: {
                title: string;
                description: string;
                btnText: string;
            },
            discord: {
                title: string;
                description: string;
                btnText: string;
            },
            vk: {
                title: string;
                description: string;
                btnText: string;
            },
            password: {
                title: string;
                description: string;
                btnText: string;
            },
            email: {
                title: string;
                description: string;
                btnText: string;
            },
        },
        userInfoBlock: {
            userId: {
                title: 'mainPage.userInfoBlock.userId.title',
            },
            email: {
                title: 'mainPage.userInfoBlock.email.title',
            },
            playedGamesCount: {
                title: 'mainPage.userInfoBlock.playedGamesCount.title',
            },
            subTime: {
                title: 'mainPage.userInfoBlock.subTime.title',
                infinity: 'mainPage.userInfoBlock.subTime.infinity',
            },
            crashTime: {
                title: 'mainPage.userInfoBlock.crashTime.title',
            },
        }
    },
    pluginsPage: {
        developer: string;
        pluginType: string;
        price: string;
        description: string;
        enablePlugin: string;
        disablePlugin: string;
        info: string;
        close: string;
        freePlugin: string;
        hero: string;
        library: string;
        utility: string;
        installed: string;
        installPlugin: string;
        deletePlugin: string;
        overview: string;
        video_overview: string;
    },

    daysBefore: string;
    day: {
        day: string;
        day_0: string;
        day_1: string;
        day_2: string;
        day_3: string;
        day_4: string;
        day_5: string;
        day_plural: string;
    },
    notifications: {
        payment: string;
        added: string;
        processing: string;
        successDone: string;
        processingDescription: string;
        plugin_updated: string;
        client_update_ready: string;
    },
    resetPassword: {
        resetPage: {
            title: string
            setNewPassword: string
            newPassword: string
            newPasswordConfirm: string
        }
        confirmPage: {
            title: string;
            text1: string;
            text2: string
        },
        forgotPasswordPage: {
            title: string,
            reset: string
        }
    },
    cookies: {
        text: 'cookies.text',
        text2: 'cookies.text2',
    }, // Мы используем cookies для быстрой и удобной работы сайта. Продолжая пользоваться сайтом, вы принимаете условия обработки персональных данных
    welcomePage: {
        tryCheat: string;
        dontOpenMouths: string;
        registerNow: string;
        registration: string;
        optimized: string;
        littleFps: string;
        cool: string;
        login: string;
        signUp: string;
        aboutUs: string;
        agreement: string;
        privacy: string;
        contacts: string;
        feature: string;

        top: {
            stream: 'welcomePage.top.stream', //Прямая трансляция
        },
        carousel: {
            one: {
                title: 'welcomePage.carousel.one.title', //ПРОВЕРЕННЫЙ И УВЕРЕННЫЙ ЛИДЕР В ДАННОЙ СФЕРЕ!
                text: 'welcomePage.carousel.one.text', //ПОЖАЛУЙ ЛУЧШИЙ ПРИВАТНЫЙ ЧИТ ДЛЯ ИГРЫ
                action: 'welcomePage.carousel.one.action', //Подробнее
                action2: 'welcomePage.carousel.one.action2', //Приобрести
            },
            two: {
                title: 'welcomePage.carousel.two.title', //НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!
                text: 'welcomePage.carousel.two.text', //ВСТУПАЙ В НАШ СЕРВЕР
                text2: 'welcomePage.carousel.two.text2', //ПОЛУЧАЙ ПОДАРКИ!
                action: 'welcomePage.carousel.two.action', //О Нас
                action2: 'welcomePage.carousel.two.action2', //Вступить
            },
            three: {
                title: 'welcomePage.carousel.three.title', //БУДУЩЕЕ УЖЕ БЛИЖЕ ЧЕМ ТЕБЕ КАЖЕТСЯ! © Divine
                text: 'welcomePage.carousel.three.text', //ЗАБУДЬ О ПРОИГРЫШАХ С ЭКО СИСТЕМОЙ
                text2: 'welcomePage.carousel.three.text2', //ПЕРВЫЙ В МИРЕ ПЛАГИН С ЛИЧНЫМ ЯДРОМ ДЛЯ РАБОТЫ! 0% ОШИБОК
                action: 'welcomePage.carousel.three.action', //Смотреть обзор
                action2: 'welcomePage.carousel.three.action2', //Приобрести
            },
            four: {
                title: 'welcomePage.carousel.four.title', //НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!
                text: 'welcomePage.carousel.four.text', //ВСТУПАЙ В
                text2: 'welcomePage.carousel.four.text2', //СООБЩЕСТВО
                text3: 'welcomePage.carousel.four.text3', //В ВКОНТАКТЕ И ПОЛУЧАЙ ПОДАРКИ!
                action: 'welcomePage.carousel.four.action', //О нас
                action2: 'welcomePage.carousel.four.action2', //Вступить
            },
            five: {
                title: 'welcomePage.carousel.five.title', //Побеждать с Divine Легко!
                text: 'welcomePage.carousel.five.text', //НОВЫЙ ИНТЕЛЕКТУАЛЬНЫЙ
                text2: 'welcomePage.carousel.five.text', //HEX lion’а не проблема!
                action: 'welcomePage.carousel.five.action', //Смотреть обзор
                action2: 'welcomePage.carousel.five.action2', //Приобрести
            },
        },
        welcomeText: {
            title: 'welcomePage.welcomeText.title', //Добро пожаловать в Divine!
            text: 'welcomePage.welcomeText.text', //Входите в читерскую игровую зону Dota 2 - Вместе с Divine!
        },
        description: 'welcomePage.description', //Наш уникальный чит открывает невероятные возможности
                                                // для поднятия вашего рейтинга! Функции отдаление камеры,
                                                // подсветка врагов сквозь туман войны, автоматический уворт
                                                // и добивание крипов — это лишь малая часть наших функций.
                                                // Новым пользователям мы предоставляем пробный период
                                                // за подписку на наши сообщества и привязку VK или Discord.
        halloween: {
            text: 'welcomePage.halloween.text', //Просто убийственно загробные скидки!
            text2: 'welcomePage.halloween.text2', //Скидка 25% на месячную подписку
            text3: 'welcomePage.halloween.text3', //С 31 октября по 2 ноября
            action: 'welcomePage.halloween.action', //Перейти к покупке
        },
        crasher: {
            text: 'welcomePage.crasher.text', //Просто убийственно загробные скидки!
            text2: 'welcomePage.crasher.text2', //Скидка 25% на месячную подписку
            text3: 'welcomePage.crasher.text3', //С 31 октября по 2 ноября
            text4: 'welcomePage.crasher.text4', //С 31 октября по 2 ноября
            action: 'welcomePage.crasher.action', //Перейти к покупке
        },
        youtube: {
            title: 'welcomePage.youtube.title', //Смотри обзоры уже прямо сейчас!
            text: 'welcomePage.youtube.text', //Погрузитесь в любимую игру с Divine - Dota 2!
            action: 'welcomePage.youtube.action', //Смотреть обзоры Divine
        },
        buy: {
            title: 'welcomePage.buy.title', //Приобретай подписку по сочным ценам!
            action: 'welcomePage.buy.action', //СОВЕРШИТЬ УДАЧНУЮ ПОКУПКУ
            price: 'welcomePage.buy.price', //Цена
            client: 'welcomePage.buy.client', //Клиент
            item1: {
                pre1: 'welcomePage.buy.item1.pre1', //ЛИДЕР ПРОДАЖ
                pre2: 'welcomePage.buy.item1.pre2', //В СЕГМЕНТЕ ТОВАР МЕСЯЦА
                name: 'welcomePage.buy.item1.name', //ПОДПИСКА 7 ДНЕЙ
                price: 'welcomePage.buy.item1.price', //₽ 150.00
            },
            item2: {
                pre1: 'welcomePage.buy.item2.pre1', //ЛИДЕР ПРОДАЖ
                pre2: 'welcomePage.buy.item2.pre2', //В СЕГМЕНТЕ ТОВАР ГОДА
                name: 'welcomePage.buy.item2.name', //ПОДПИСКА 14 ДНЕЙ
                price: 'welcomePage.buy.item2.price', //₽ 266.00
            },
            item3: {
                pre1: 'welcomePage.buy.item3.pre1', //В СЕГМЕНТЕ ТОВАР ГОДА
                pre2: 'welcomePage.buy.item3.pre2', //ПОПУЛЯРНЫЙ ВЫБОР
                name: 'welcomePage.buy.item3.name', //ПОДПИСКА 30 ДНЕЙ
                price: 'welcomePage.buy.item3.price', //₽ 504.00
            },
            item4: {
                pre1: 'welcomePage.buy.item4.pre1', //ЛУЧШАЯ ЦЕНА
                pre2: 'welcomePage.buy.item4.pre2', //ЛУЧШИЙ ВЫБОР ДЛЯ ПОКУПКИ
                name: 'welcomePage.buy.item4.name', //ПОДПИСКА 90 ДНЕЙ
                price: 'welcomePage.buy.item4.price', //₽ 1440.00
            },
        },
        trialGamesBlock: {
            title: 'welcomePage.trialGamesBlock.title', //Побеждай и апай MMR
            title2: 'welcomePage.trialGamesBlock.title2', //Вместе с DIVINE!
            description: 'welcomePage.trialGamesBlock.description', //DOTA 2 SCRIPTS
            tryToFree: 'welcomePage.trialGamesBlock.tryForFree', //Попробовать бесплатно
            modal: {
                title: 'welcomePage.trialGamesBlock.modal.title', //ПОЛУЧЕНИЕ ПРОБНОГО ПЕРИОДА
                title2: 'welcomePage.trialGamesBlock.modal.title2', //Мы предоставляем пробный период всем нашим пользователям.
                p: 'welcomePage.trialGamesBlock.modal.p', //1. Мы предоставляем пробный период всем нашим пользователям которые подписались на наши социальные сети
                p1: 'welcomePage.trialGamesBlock.modal.p1', //или
                p2: 'welcomePage.trialGamesBlock.modal.p2', //2. Зарегистрируйтесь на сайте и привижите вашу учётную запись
                p22: 'welcomePage.trialGamesBlock.modal.p22', //в личном кабинете.
                p3: 'welcomePage.trialGamesBlock.modal.p3', //3. Скачайте и запустите клиент.
                p4: 'welcomePage.trialGamesBlock.modal.p4', //4. Можете заходить в игру.
                p5: 'welcomePage.trialGamesBlock.modal.p5', //5. только для китайцев
                action: 'welcomePage.trialGamesBlock.modal.action', //Подписаться на группу ВКонтакте
            }
        }
    },
    aboutUs: {
        title: string,
        text: string,
        back: string,
    },
    subInfo: {
        title: string,
        block: {
            subTime: {
                subTime: string;
                subTime_0: string;
                subTime_1: string;
                subTime_2: string;
                day_plural: string;
            },
            feature1: string,
            feature2: string,
            feature3: string,
            feature4: string,
            buySub: string,
        },
        extra: string
        extra_color: string
    },
    buySubBlock: {
        welcomeText: string,
        saleText: string,
        colOne: {
            title: string,
            description: string,
        }
        colTwo: {
            title: string,
            description: string,
        }
    },
    configPage: {
        author: 'configPage.author',
        all: {
            title: 'configPage.all.title',
            install: 'configPage.all.install'
        },
        myConfigs: {
            enabled: 'configPage.myConfigs.enabled',
            disabled: 'configPage.myConfigs.disabled',
            title: 'configPage.myConfigs.title',
        }
    },
    confirmEmailPage: {
        title: string;
        urEmail: string;
        description: string;
    },
    regularUser: {
        title: string,
        description: string,
    }
}

const keys: ITranslationKeys = {
    regularUser: {
        title: 'regularUser.title',
        description: 'regularUser.description',
    },
    confirmEmailPage: {
        title: 'confirmEmailPage.title',
        urEmail: 'confirmEmailPage.urEmail',
        description: 'confirmEmailPage.description',
    },
    cookies: {
        text: 'cookies.text',
        text2: 'cookies.text2',
    },
    invalidTime: 'invalidTime',
    account: {
        langSelector: 'account.langSelector',
        newEmail: 'account.newEmail',
        activateToken: 'account.activateToken',
        acceptToken: 'account.acceptToken',
        resetHwid: 'account.resetHwid',
        changePassword: 'account.changePassword',
        email: 'account.email',
        confirmEmail: 'account.confirmEmail',
        confirmedEmail: 'account.confirmedEmail',
        changeEmail: 'account.changeEmail',
        hwid: 'account.hwid',
    },
    configPage: {
        author: 'configPage.author',
        all: {
            title: 'configPage.all.title',
            install: 'configPage.all.install'
        },
        myConfigs: {
            enabled: 'configPage.myConfigs.enabled',
            disabled: 'configPage.myConfigs.disabled',
            title: 'configPage.myConfigs.title',
        }
    },
    notifications: {
        payment: 'notifications.payment',
        added: 'notifications.added',
        successDone: 'notifications.successDone',
        processingDescription: 'notifications.processingDescription',
        processing: 'notifications.processing',
        plugin_updated: 'notifications.plugin_updated',
        client_update_ready: 'notifications.client_update_ready',
    },
    forum: {
        create: 'forum.create',
        hidden: 'forum.hidden',
        delete: 'forum.delete',
        createPage: 'forum.createPage',
        createTheme: 'forum.createTheme',
        sendMessage: 'forum.sendMessage',
        created: 'forum.created',
        updated: 'forum.updated',
        startedBy: 'forum.startedBy',
        comment_base: {
            comment: 'forum.comment_base.comment',
            comment_0: 'forum.comment_base.comment_0',
            comment_1: 'forum.comment_base.comment_1',
            comment_2: 'forum.comment_base.comment_2',
            comment_3: 'forum.comment_base.comment_3',
            comment_4: 'forum.comment_base.comment_4',
            comment_5: 'forum.comment_base.comment_5',
            comment_plural: 'forum.comment_base.comment_plural',
        },
        vehicle: {
            vehicle_0: 'vehicle.vehicle_0',
            vehicle_1: 'vehicle.vehicle_1',
            vehicle_2: 'vehicle.vehicle_2',
            vehicle: 'vehicle.vehicle',
            vehicle_plural: 'vehicle.vehicle_plural'
        },
    },
    day: {
        day: "day.day",
        day_0: "day.day_0",
        day_1: "day.day_1",
        day_2: "day.day_2",
        day_3: "day.day_3",
        day_4: "day.day_4",
        day_5: "day.day_5",
        day_plural: 'day.day_plural'
    },
    daysBefore: "daysBefore",
    download: "download",
    downloadInfo: "downloadInfo",
    welcomeHi: "welcomeHi",
    welcomeInfo: "welcomeInfo",
    acceptPayments: "acceptPayments",
    pluginsPage: {
        close: "pluginsPage.close",
        description: "pluginsPage.description",
        developer: "pluginsPage.developer",
        enablePlugin: "pluginsPage.enablePlugin",
        disablePlugin: "pluginsPage.disablePlugin",
        info: "pluginsPage.info",
        freePlugin: "pluginsPage.freePlugin",
        hero: "pluginsPage.hero",
        library: "pluginsPage.library",
        pluginType: "pluginsPage.pluginType",
        price: "pluginsPage.price",
        utility: "pluginsPage.utility",
        installed: "pluginsPage.installed",
        installPlugin: "pluginsPage.installPlugin",
        deletePlugin: "pluginsPage.deletePlugin",
        overview: "pluginsPage.overview",
        video_overview: "pluginsPage.video_overview",
    },
    mainPage: {
        downloadClient: "mainPage.downloadClient",
        downloadingResources: "mainPage.downloadingResources",
        downloadingClient: "mainPage.downloadingClient",
        lastPluginsUpdate: "mainPage.lastPluginsUpdate",
        protection: "mainPage.protection",
        showAll: "mainPage.showAll",
        startDivineClient: "mainPage.startDivineClient",
        startDota: "mainPage.startDota",
        startingClient: "mainPage.startingClient",
        systemStatus: "mainPage.systemStatus",
        uptime: "mainPage.uptime",
        working: "mainPage.working",
        paymentBlock: {
            amount: "mainPage.paymentBlock.amount",
            discount: "mainPage.paymentBlock.discount",
            funPay: {
                btn: "mainPage.paymentBlock.funPay.btn",
                tooltip: "mainPage.paymentBlock.funPay.tooltip"
            },
            freeKassa: {
                btn: "mainPage.paymentBlock.freeKassa.btn",
                tooltip: "mainPage.paymentBlock.freeKassa.tooltip"
            },
            sellix: {
                btn: "mainPage.paymentBlock.sellix.btn",
                tooltip: "mainPage.paymentBlock.sellix.tooltip"
            },
            paymaster: {
                btn: "mainPage.paymentBlock.paymaster.btn",
                tooltip: "mainPage.paymentBlock.paymaster.tooltip"
            },
            payDigi: {
                btn: "mainPage.paymentBlock.payDigi.btn",
                tooltip: "mainPage.paymentBlock.payDigi.tooltip"
            },
            primePayments: {
                btn: "mainPage.paymentBlock.primePayments.btn",
                tooltip: "mainPage.paymentBlock.primePayments.tooltip"
            },
            paymax: {
                btn: "mainPage.paymentBlock.paymax.btn",
                tooltip: "mainPage.paymentBlock.paymax.tooltip"
            },
            paypal: {
                btn: "mainPage.paymentBlock.paypal.btn",
                tooltip: "mainPage.paymentBlock.paypal.tooltip"
            },
            reseller: {
                btn: "mainPage.paymentBlock.reseller.btn",
                tooltip: "mainPage.paymentBlock.reseller.tooltip",
            },
            chineseReseller: {
                btn: "mainPage.paymentBlock.chineseReseller.btn",
                tooltip: "mainPage.paymentBlock.chineseReseller.tooltip",
            },
            title: "mainPage.paymentBlock.title",
            tooltip: {
                title: "mainPage.paymentBlock.tooltip.title",
                row1: "mainPage.paymentBlock.tooltip.row1",
                row2: "mainPage.paymentBlock.tooltip.row2",
                row3: "mainPage.paymentBlock.tooltip.row3",
                extra: "mainPage.paymentBlock.tooltip.extra",
            }
        },
        transactionBlock: {
            title: "mainPage.transactionBlock.title",
        },
        subLifeTimeBlock: {
            title: "mainPage.subLifeTimeBlock.title",
        },
        othersBlock: {
            activeToken: {
                title: 'mainPage.othersBlock.activeToken.title',
                description: 'mainPage.othersBlock.activeToken.description',
                btnText: 'mainPage.othersBlock.activeToken.btnText',
            },
            hwid: {
                title: 'mainPage.othersBlock.hwid.title',
                description: 'mainPage.othersBlock.hwid.description',
                btnText: 'mainPage.othersBlock.hwid.btnText',
            },
            discord: {
                title: 'mainPage.othersBlock.discord.title',
                description: 'mainPage.othersBlock.discord.description',
                btnText: 'mainPage.othersBlock.discord.btnText',
            },
            vk: {
                title: 'mainPage.othersBlock.vk.title',
                description: 'mainPage.othersBlock.vk.description',
                btnText: 'mainPage.othersBlock.vk.btnText',
            },
            password: {
                title: 'mainPage.othersBlock.password.title',
                description: 'mainPage.othersBlock.password.description',
                btnText: 'mainPage.othersBlock.password.btnText',
            },
            email: {
                title: 'mainPage.othersBlock.email.title',
                description: 'mainPage.othersBlock.email.description',
                btnText: 'mainPage.othersBlock.email.btnText',
            },
        },
        userInfoBlock: {
            userId: {
                title: 'mainPage.userInfoBlock.userId.title',
            },
            email: {
                title: 'mainPage.userInfoBlock.email.title',
            },
            playedGamesCount: {
                title: 'mainPage.userInfoBlock.playedGamesCount.title',
            },
            subTime: {
                title: 'mainPage.userInfoBlock.subTime.title',
                infinity: 'mainPage.userInfoBlock.subTime.infinity',
            },
            crashTime: {
                title: 'mainPage.userInfoBlock.crashTime.title',
            },
        }
    },
    cancel: 'cancel',
    save: 'save',
    showOnMap: 'showOnMap',
    lefPanel: {
        dashboard: {
            title: 'lefPanel.dashboard.title',
            main: 'lefPanel.dashboard.main',
            forum: 'lefPanel.dashboard.forum',
            buy: 'lefPanel.dashboard.buy',
            plugins: 'lefPanel.dashboard.plugins',
            addPlugin: 'lefPanel.dashboard.addPlugin',
            localPlugin: 'lefPanel.dashboard.localPlugin',
            cloudConfig: 'lefPanel.dashboard.cloudPlugin',
            account: 'lefPanel.dashboard.account',
        },
        admin: {
            title: 'lefPanel.admin.title',
            users: 'lefPanel.admin.users',
            logs: 'lefPanel.admin.logs',
            statistics: 'lefPanel.admin.statistics',
            loadLibrary: 'lefPanel.admin.loadLibrary',
            pluginManager: 'lefPanel.admin.pluginManager',
            oldMenu: 'lefPanel.admin.oldMenu'
        },
        hide: 'lefPanel.hide'
    },
    resetPassword: {
        resetPage: {
            title: 'resetPassword.resetPage.title',
            setNewPassword: 'resetPassword.resetPage.setNewPassword',
            newPassword: 'resetPassword.resetPage.newPassword',
            newPasswordConfirm: 'resetPassword.resetPage.newPasswordConfirm',
        },
        confirmPage: {
            title: 'resetPassword.confirmPage.title',
            text1: 'resetPassword.confirmPage.text1',
            text2: 'resetPassword.confirmPage.text2',
        },
        forgotPasswordPage: {
            title: 'resetPassword.forgotPasswordPage.title',
            reset: 'resetPassword.forgotPasswordPage.reset'
        }
    },
    welcomePage: {
        tryCheat: 'welcomePage.tryCheat',
        dontOpenMouths: 'welcomePage.dontOpenMouths',
        registerNow: 'welcomePage.registerNow',
        registration: 'welcomePage.registration',
        optimized: 'welcomePage.optimized',
        littleFps: 'welcomePage.littleFps',
        cool: 'welcomePage.cool',
        login: 'welcomePage.login',
        signUp: 'welcomePage.signUp',
        aboutUs: 'welcomePage.aboutUs',
        agreement: 'welcomePage.agreement',
        privacy: 'welcomePage.privacy',
        contacts: 'welcomePage.contacts',
        feature: 'welcomePage.feature',
        top: {
            stream: 'welcomePage.top.stream', //Прямая трансляция
        },
        carousel: {
            one: {
                title: 'welcomePage.carousel.one.title', //ПРОВЕРЕННЫЙ И УВЕРЕННЫЙ ЛИДЕР В ДАННОЙ СФЕРЕ!
                text: 'welcomePage.carousel.one.text', //ПОЖАЛУЙ ЛУЧШИЙ ПРИВАТНЫЙ ЧИТ ДЛЯ ИГРЫ
                action: 'welcomePage.carousel.one.action', //Подробнее
                action2: 'welcomePage.carousel.one.action2', //Приобрести
            },
            two: {
                title: 'welcomePage.carousel.two.title', //НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!
                text: 'welcomePage.carousel.two.text', //ВСТУПАЙ В НАШ СЕРВЕР
                text2: 'welcomePage.carousel.two.text2', //ПОЛУЧАЙ ПОДАРКИ!
                action: 'welcomePage.carousel.two.action', //О Нас
                action2: 'welcomePage.carousel.two.action2', //Вступить
            },
            three: {
                title: 'welcomePage.carousel.three.title', //БУДУЩЕЕ УЖЕ БЛИЖЕ ЧЕМ ТЕБЕ КАЖЕТСЯ! © Divine
                text: 'welcomePage.carousel.three.text', //ЗАБУДЬ О ПРОИГРЫШАХ С ЭКО СИСТЕМОЙ
                text2: 'welcomePage.carousel.three.text2', //ПЕРВЫЙ В МИРЕ ПЛАГИН С ЛИЧНЫМ ЯДРОМ ДЛЯ РАБОТЫ! 0% ОШИБОК
                action: 'welcomePage.carousel.three.action', //Смотреть обзор
                action2: 'welcomePage.carousel.three.action2', //Приобрести
            },
            four: {
                title: 'welcomePage.carousel.four.title', //НОВАЯ ЭРА В СФЕРЕ ЧИТОВ ДЛЯ Dota 2!
                text: 'welcomePage.carousel.four.text', //ВСТУПАЙ В
                text2: 'welcomePage.carousel.four.text2', //СООБЩЕСТВО
                text3: 'welcomePage.carousel.four.text3', //В ВКОНТАКТЕ И ПОЛУЧАЙ ПОДАРКИ!
                action: 'welcomePage.carousel.four.action', //О нас
                action2: 'welcomePage.carousel.four.action2', //Вступить
            },
            five: {
                title: 'welcomePage.carousel.five.title', //Побеждать с Divine Легко!
                text: 'welcomePage.carousel.five.text', //НОВЫЙ ИНТЕЛЕКТУАЛЬНЫЙ
                text2: 'welcomePage.carousel.five.text', //HEX lion’а не проблема!
                action: 'welcomePage.carousel.five.action', //Смотреть обзор
                action2: 'welcomePage.carousel.five.action2', //Приобрести
            },
        },
        welcomeText: {
            title: 'welcomePage.welcomeText.title', //Добро пожаловать в Divine!
            text: 'welcomePage.welcomeText.text', //Входите в читерскую игровую зону Dota 2 - Вместе с Divine!
        },
        description: 'welcomePage.description', //Наш уникальный чит открывает невероятные возможности
                                                // для поднятия вашего рейтинга! Функции отдаление камеры,
                                                // подсветка врагов сквозь туман войны, автоматический уворт
                                                // и добивание крипов — это лишь малая часть наших функций.
                                                // Новым пользователям мы предоставляем пробный период
                                                // за подписку на наши сообщества и привязку VK или Discord.
        halloween: {
            text: 'welcomePage.halloween.text', //Просто убийственно загробные скидки!
            text2: 'welcomePage.halloween.text2', //Скидка 25% на месячную подписку
            text3: 'welcomePage.halloween.text3', //С 31 октября по 2 ноября
            action: 'welcomePage.halloween.action', //Перейти к покупке
        },
        crasher: {
            text: 'welcomePage.crasher.text', //Просто убийственно загробные скидки!
            text2: 'welcomePage.crasher.text2', //Скидка 25% на месячную подписку
            text3: 'welcomePage.crasher.text3', //С 31 октября по 2 ноября
            text4: 'welcomePage.crasher.text4', //С 31 октября по 2 ноября
            action: 'welcomePage.crasher.action', //Перейти к покупке
        },
        youtube: {
            title: 'welcomePage.youtube.title', //Смотри обзоры уже прямо сейчас!
            text: 'welcomePage.youtube.text', //Погрузитесь в любимую игру с Divine - Dota 2!
            action: 'welcomePage.youtube.action', //Смотреть обзоры Divine
        },
        buy: {
            title: 'welcomePage.buy.title', //Приобретай подписку по сочным ценам!
            action: 'welcomePage.buy.action', //СОВЕРШИТЬ УДАЧНУЮ ПОКУПКУ
            price: 'welcomePage.buy.price', //Цена
            client: 'welcomePage.buy.client', //Клиент
            item1: {
                pre1: 'welcomePage.buy.item1.pre1', //ЛИДЕР ПРОДАЖ
                pre2: 'welcomePage.buy.item1.pre2', //В СЕГМЕНТЕ ТОВАР МЕСЯЦА
                name: 'welcomePage.buy.item1.name', //ПОДПИСКА 7 ДНЕЙ
                price: 'welcomePage.buy.item1.price', //₽ 150.00
            },
            item2: {
                pre1: 'welcomePage.buy.item2.pre1', //ЛИДЕР ПРОДАЖ
                pre2: 'welcomePage.buy.item2.pre2', //В СЕГМЕНТЕ ТОВАР ГОДА
                name: 'welcomePage.buy.item2.name', //ПОДПИСКА 14 ДНЕЙ
                price: 'welcomePage.buy.item2.price', //₽ 266.00
            },
            item3: {
                pre1: 'welcomePage.buy.item3.pre1', //В СЕГМЕНТЕ ТОВАР ГОДА
                pre2: 'welcomePage.buy.item3.pre2', //ПОПУЛЯРНЫЙ ВЫБОР
                name: 'welcomePage.buy.item3.name', //ПОДПИСКА 30 ДНЕЙ
                price: 'welcomePage.buy.item3.price', //₽ 504.00
            },
            item4: {
                pre1: 'welcomePage.buy.item4.pre1', //ЛУЧШАЯ ЦЕНА
                pre2: 'welcomePage.buy.item4.pre2', //ЛУЧШИЙ ВЫБОР ДЛЯ ПОКУПКИ
                name: 'welcomePage.buy.item4.name', //ПОДПИСКА 90 ДНЕЙ
                price: 'welcomePage.buy.item4.price', //₽ 1440.00
            },
        },
        trialGamesBlock: {
            title: 'welcomePage.trialGamesBlock.title', //Побеждай и апай MMR
            title2: 'welcomePage.trialGamesBlock.title2', //Вместе с DIVINE!
            description: 'welcomePage.trialGamesBlock.description', //DOTA 2 SCRIPTS
            tryToFree: 'welcomePage.trialGamesBlock.tryForFree', //Попробовать бесплатно
            modal: {
                title: 'welcomePage.trialGamesBlock.modal.title', //ПОЛУЧЕНИЕ ПРОБНОГО ПЕРИОДА
                title2: 'welcomePage.trialGamesBlock.modal.title2', //Мы предоставляем пробный период всем нашим пользователям.
                p: 'welcomePage.trialGamesBlock.modal.p', //1. Мы предоставляем пробный период всем нашим пользователям которые подписались на наши социальные сети
                p1: 'welcomePage.trialGamesBlock.modal.p1', //или
                p2: 'welcomePage.trialGamesBlock.modal.p2', //2. Зарегистрируйтесь на сайте и привижите вашу учётную запись
                p22: 'welcomePage.trialGamesBlock.modal.p22', //в личном кабинете.
                p3: 'welcomePage.trialGamesBlock.modal.p3', //3. Скачайте и запустите клиент.
                p4: 'welcomePage.trialGamesBlock.modal.p4', //4. Можете заходить в игру.
                p5: 'welcomePage.trialGamesBlock.modal.p5', //5. только для китайцев
                action: 'welcomePage.trialGamesBlock.modal.action', //Подписаться на группу ВКонтакте
            }
        }
    },
    aboutUs: {
        title: 'aboutUs.title',
        text: 'aboutUs.text',
        back: 'aboutUs.back',
    },
    subInfo: {
        title: 'subInfo.title',
        block: {
            subTime: {
                subTime: 'subInfo.block.subTime.subTime',
                subTime_0: 'subInfo.block.subTime.subTime_0',
                subTime_1: 'subInfo.block.subTime.subTime_1',
                subTime_2: 'subInfo.block.subTime.subTime_2',
                day_plural: 'subInfo.block.subTime.day_plural',
            },
            feature1: 'subInfo.block.feature1',
            feature2: 'subInfo.block.feature2',
            feature3: 'subInfo.block.feature3',
            feature4: 'subInfo.block.feature4',
            buySub: 'subInfo.block.buySub',
        },
        extra: 'subInfo.extra',
        extra_color: 'subInfo.extra_color',
    },
    buySubBlock: {
        welcomeText: 'buySubBlock.welcomeText',
        saleText: 'buySubBlock.saleText',
        colOne: {
            title: 'buySubBlock.colOne.title',
            description: 'buySubBlock.colOne.description',
        },
        colTwo: {
            title: 'buySubBlock.colTwo.title',
            description: 'buySubBlock.colTwo.description',
        }
    }
};
export default keys;
