import Slider from "rc-slider";
import React, {useContext, useEffect, useState} from "react";
import Button from "../../../Components/Button";
import {IPriceInfo, usePayment} from "../../../Hooks/usePayment";
import {Icon, Popup} from "semantic-ui-react";
import keys from "../../../translations/keys";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../Context/LanguageContext";
import {Link, useLocation} from "react-router-dom";
// @ts-ignore
import AnimatedNumber from "animated-number-react";
import {DataContext} from "../../../Context/DataContext";
import PaypalButton from "../../../Components/PaypalButton";
import _ from "underscore";
import useAuth from "../../../Hooks/useAuth";

export type IBuySubBlockProps = {};


const BuySubBlock = ({}: IBuySubBlockProps) => {
    const {isLoading: isPricesLoading, prices, isOldCostumer} = useContext(DataContext);
    const { isLoading, getPaymentLink, getPaymentLinkForPaymaster, getPrimePaymentsPaymentLink, getPaymaxPaymentLink } = usePayment();
    const [price, setPrice] = useState(3.5);
    const [shopiData, setShopiData] = useState('');
    const {claims} = useAuth();
    const claimsToHave = ['r_devpanel_in_loader']
    const hasClaims = claimsToHave ? _.every(claimsToHave, x => _.contains(claims, x)) : true;
    const lables = [3, 15, 30, 50, 90];
    // const [prices, setPrices] = useState([] as IPriceInfo[]);
    const [selectedPrice, setSelectedPrice] = useState({} as IPriceInfo);
    // const [checkout, setCheckout] = useState({} as any);
    // const {onOpen, isOpen, onToggle, onClose} = useModalState();
    const {t} = useTranslation();
    // const location = useLocation();

    const { lang } = useContext(LanguageContext);

    useEffect(() => {
        if (prices && prices.length > 0) {
            const firstPrice: number = prices[3].usdPrice;
            setPrice(firstPrice);
            setSelectedPrice(prices[3]);
            setPrice(firstPrice)
        }
    }, [prices])

    const priceTable: any = {
        // 3: prices[0],
        3: prices[1],
        15: prices[2],
        30: prices[3],
        50: prices[4],
        90: prices[5],
    }


    const daysTable: any = {
        // 3: prices[0]?.days,
        3: prices[1]?.days,
        15: prices[2]?.days,
        30: prices[3]?.days,
        50: prices[4]?.days,
        90: prices[5]?.days,
    }

    const sendToPayment = async () => {
        const paymentUrl = await getPaymentLink(selectedPrice.id, "RUB");
        window.location = paymentUrl;
    }

    const sendToPaymentPaymaster = async () => {
        const paymentUrl = await getPaymentLinkForPaymaster(selectedPrice.id, "RUB");
        // const paymentUrl = "https://paymaster.ru/payment/init?LMI_CURRENCY=RUB&LMI_PAYMENT_DESC=Divine+30+days+sub&LMI_PAYMENT_AMOUNT=500&LMI_MERCHANT_ID=0ed3dc1f-5d48-4257-be94-b7b937937b5c&LMI_PAYER_EMAIL=JumpAttacker%40gmail.com&LMI_PAYMENT_METHOD=paymaster";

        window.location.href = paymentUrl;
    }

    const digiHandler = async () => {
        window.location.href = selectedPrice.digUrl;
    }
    const primePaymentsHandler = async () => {
        const paymentUrl = await getPrimePaymentsPaymentLink(selectedPrice.id, "RUB");
        window.location = paymentUrl;
    }

    const paymaxHandler = async () => {
        const paymentUrl = await getPaymaxPaymentLink(selectedPrice.id, "RUB");
        window.location = paymentUrl;
    }

    const funPayHandler = async () => {
        let offerId;

        if (selectedPrice.isCis) {
            switch (selectedPrice.id) {
                case 3:
                    offerId = 28665767;
                    break;
                case 2:
                    offerId = 28675640;
                    break;
                case 1:
                    offerId = 28675721;
                    break;
                case 4:
                    offerId = 28675788;
                    break;
                case 6:
                    offerId = 28675842;
                    break;
            }
        }
        else {
            switch (selectedPrice.id) {
                case 3:
                    offerId = 38619788;
                    break;
                case 2:
                    offerId = 38619867;
                    break;
                case 1:
                    offerId = 38620028;
                    break;
                case 4:
                    offerId = 38620049;
                    break;
                case 6:
                    offerId = 38620084;
                    break;
            }
        }


        window.location.href = `https://funpay.com/lots/offer?id=${offerId}`;
    }

    const onChange = (value: number | number[]) => {
        const table = priceTable[value as number];
        const calculatedPrice: number = table?.usdPrice;
        // const calculatedDays: any = daysTable[value];
        // console.log('table', table)
        // console.log('calculatedPrice', calculatedPrice)
        setSelectedPrice(table);
        setPrice(calculatedPrice)
        // if (calculatedDays.value && calculatedDays.bonus) {
        //     setDays(Math.floor(calculatedDays.value + calculatedDays.value / 100 * calculatedDays.bonus))
        // } else {
        //     setDays(calculatedDays)
        // }

    }

    const getMarks = () => {
        const marks: any = {};
        if (!prices || prices.length === 0)
            return marks;
        lables.forEach((item) => {
            console.log('item', item)
            const result = daysTable[item];
            console.log('result', result)
            if (result.value && result.bonus) {
                marks[item] = <div className="daysContainer">
                    {t(keys.day.day, {count: result.value.replace('Days', '')})}
                    <div className="daysContainer__bonus">
                        {`+${result.bonus}%`}
                    </div>

                </div>
            } else {
                marks[item] = <div className="daysContainer">
                    {t(keys.day.day, {count: parseInt(result.replace('Days', ''))})}
                    {/*{result}*/}
                </div>
            }
        })
        return marks;
    }

    const sellixLinkHandler = () => {
        window.location.href = 'https://divinewtf.mysellix.io/product/63c1bbd41d3b8'
    }

    const paypalLinkHandler = () => {
        // window.location.href = 'https://www.paypal.com/paypalme/Jumpering'
        // window.location.href = 'https://Paypal.me/mralphm'
        window.location.href = 'https://discord.com/channels/721518056416739388/857952386769485835/968029775337758731'
        // window.location.href = 'https://paypal.me/DivineDota'
    }

    const discordLinkHandler = () => {
        window.open('https://discord.gg/YvFuC6H');
    }
    const chinaLinkHandler = () => {
        window.open('https://qm.qq.com/q/WHlyvrkOcw');
    }

    const info = () => {
        return <Popup content={<div className="block-buy_sub__popup">
            <div className="block-buy_sub__popup--title">
                {t(keys.mainPage.paymentBlock.tooltip.title)}
            </div>
            <div className="block-buy_sub__popup--content">
                <div className="row">* {t(keys.mainPage.paymentBlock.tooltip.row1)}</div>
                <div className="row">* {t(keys.mainPage.paymentBlock.tooltip.row2)}</div>
                <div className="row">* {t(keys.mainPage.paymentBlock.tooltip.row3)}</div>
            </div>
            <div className="block-buy_sub__popup--content-extra">
                <div className="row">{t(keys.mainPage.paymentBlock.tooltip.extra)}</div>
            </div>
        </div>} trigger={<Icon name={'question circle'}/>} position={'bottom center'}/>
    }

    // return <Block title={t(keys.mainPage.paymentBlock.title)} hideScrollbars className={'block-buy_sub'}
    //               info={info()}>

    return <>
        <div className="content">

            <div className="price-info">
                <div className="info">{t(keys.mainPage.paymentBlock.amount)}</div>
                <div className="prices">
                    {price > 0 && !selectedPrice.isCis && <>
                        <AnimatedNumber
                            className="price"
                            value={price}
                            formatValue={(value: string) => `$ ${Number(value).toFixed(2)}`}
                            duration={100}
                        />
                        {selectedPrice.discount >= 0 && <AnimatedNumber
                            className="price price--discount"
                            value={selectedPrice.discount}
                            formatValue={(value: string) => `${t(keys.mainPage.paymentBlock.discount)}: ${Number(value).toFixed(0)}%`}
                            duration={100}
                        />}

                    </>}
                    {selectedPrice?.price > 0 && selectedPrice.isCis && <>
                        <AnimatedNumber
                            className="price"
                            value={selectedPrice?.price}
                            formatValue={(value: string) => `${Number(value).toFixed(0)}₽`}
                            duration={100}
                        />
                        {selectedPrice.discount >= 0 && <AnimatedNumber
                            className="price price--discount"
                            value={selectedPrice.discount}
                            formatValue={(value: string) => `${t(keys.mainPage.paymentBlock.discount)}: ${Number(value).toFixed(0)}%`}
                            duration={100}
                            />}
                    </>}
                    {/*<div className="price">${price} usd</div>*/}
                    {/*<div className="price">{selectedPrice.price} ₽</div>*/}
                </div>
            </div>
            {/*<Popup trigger={<Button circular className={'paymentBtn'}*/}
            {/*                        onClick={sendToPaymentPaymaster}>{t(keys.mainPage.paymentBlock.paymaster.btn)}</Button>}*/}
            {/*       content={t(keys.mainPage.paymentBlock.paymaster.tooltip)} position={'top center'}/>*/}

            <Popup trigger={<Button disabled={!prices?.length} circular onClick={funPayHandler}>{t(keys.mainPage.paymentBlock.funPay.btn)}</Button>}
                   content={t(keys.mainPage.paymentBlock.funPay.tooltip)} position={'top center'} />
            <Popup trigger={<Button disabled={!prices?.length} circular onClick={sendToPayment}>{t(keys.mainPage.paymentBlock.freeKassa.btn)}</Button>}
                   content={t(keys.mainPage.paymentBlock.freeKassa.tooltip)} position={'top center'} />
            {/*<Popup trigger={<Button disabled={!prices?.length} circular onClick={sellixLinkHandler}>Paypal/Stripe/Visa/MasterCard</Button>}*/}
            {/*       content={t(keys.mainPage.paymentBlock.sellix.tooltip)} position={'top center'} />*/}
            <Popup trigger={<Button disabled={!prices?.length} circular onClick={paymaxHandler}>{t(keys.mainPage.paymentBlock.paymax.btn)}</Button>}
                   content={t(keys.mainPage.paymentBlock.paymax.tooltip)} position={'top center'} />
            <Popup trigger={<Button disabled={!prices?.length} circular onClick={digiHandler}>{t(keys.mainPage.paymentBlock.payDigi.btn)}</Button>}
                   content={t(keys.mainPage.paymentBlock.payDigi.tooltip)} position={'top center'} />
            {/*<Popup trigger={<Button disabled={!prices?.length} circular onClick={primePaymentsHandler}>{t(keys.mainPage.paymentBlock.primePayments.btn)}</Button>}*/}
            {/*       content={t(keys.mainPage.paymentBlock.primePayments.tooltip)} position={'top center'}/>*/}
            {/*<Popup trigger={<Button disabled={!prices?.length} circular>Paypal Reseller</Button>}*/}
            {/*       on={['click']}*/}
            {/*       content={component => {*/}
            {/*           return <div className="popup-paypal-content">*/}
            {/*               {t(keys.mainPage.paymentBlock.paypal.tooltip)}*/}
            {/*               <Button onClick={paypalLinkHandler} disabled={!prices?.length} color={'orange'}>Discord Ticket</Button>*/}
            {/*           </div>*/}
            {/*       }} position={'top center'}/>*/}
            <Popup trigger={<Button disabled={!prices?.length} circular>Paypal/Stripe/Visa/MC Reseller</Button>}
                on={['click']}
                content={component => {
                    return <div className="popup-paypal-content">
                        {t(keys.mainPage.paymentBlock.paypal.tooltip)}
                        <Button onClick={paypalLinkHandler} disabled={!prices?.length} color={'orange'}>Discord Ticket</Button>
                    </div>
                }} position={'top center'} />
            {/*<Popup trigger={<Button circular>{t(keys.mainPage.paymentBlock.paypal.btn)}</Button>}*/}
            {/*       on={['click']}*/}
            {/*       content={component => {*/}
            {/*           return <div className="popup-paypal-content">*/}
            {/*               /!*{t(keys.mainPage.paymentBlock.paypal.tooltip)}*!/*/}
            {/*               /!*<Button onClick={paypalLinkHandler} color={'orange'}>Paypal.me/DivineDota</Button>*!/*/}
            {/*               <PaypalButton priceInfo={selectedPrice}/>*/}
            {/*           </div>*/}
            {/*       }*/}
            {/*       } position={'top center'}/>*/}
            {/*<Popup trigger={<Button disabled={!prices?.length} circular>{t(keys.mainPage.paymentBlock.reseller.btn)}</Button>}*/}
            {/*       on={['click', 'hover']} pinned hoverable*/}
            {/*       content={component => {*/}
            {/*           return <div className="popup-paypal-content">*/}
            {/*               {t(keys.mainPage.paymentBlock.reseller.tooltip)}*/}
            {/*               <Button onClick={discordLinkHandler} disabled={!prices?.length} color={'orange'}>Discord</Button>*/}
            {/*               */}{/*{t(keys.mainPage.paymentBlock.reseller.tooltip2)}*/}
            {/*               */}{/*<Button onClick={chinaLinkHandler} disabled={!prices?.length} color={'orange'}>Chinese Reseller</Button>*/}
            {/*               */}{/*<Link to="route" target="_blank" className="link" onClick={(event) => {*/}
            {/*               */}{/*    event.preventDefault();*/}
            {/*               */}{/*    window.open('https://discord.gg/YvFuC6H');*/}
            {/*               */}{/*}}>Discord</Link>*/}
            {/*           </div>*/}
            {/*       }} position={'top center'}/>*/}
            <Popup trigger={<Button disabled={!prices?.length} circular onClick={chinaLinkHandler}>{t(keys.mainPage.paymentBlock.chineseReseller.btn)}</Button>}
                   content={t(keys.mainPage.paymentBlock.chineseReseller.tooltip)} position={'top center'}/>
        </div>
        {prices?.length > 0 && <div className="content--slider">
            <Slider defaultValue={30} step={null} onChange={onChange}
                    // value={selectedPrice.days}
                    railStyle={{backgroundColor: '#f6f6f6'}}
                    dotStyle={{backgroundColor: '#29292970'}}
                    activeDotStyle={{backgroundColor: '#ffffff', borderColor: '#FF8E25'}}
                    trackStyle={{backgroundColor: '#FF8E25'}}
                    handleStyle={{backgroundColor: 'white', borderColor: '#FF8E25'}}
                    marks={getMarks()}/>
        </div>}
        {isOldCostumer && <div className="congratz">
            <span>
                {t(keys.regularUser.title)}
            </span>
            <span className={'congratz__tooltip'}>
                {t(keys.regularUser.description)}
            </span>
        </div>}
    </>
    // </Block>
}

export default BuySubBlock;
